window.alert = function(message)
{
	nerivon_alert(message, "", "info");
};

function nerivon_confirm(message, text, type, close_after, callback)
{
	if(callback == null)
	{
		callback = function() {};
	}

	swal({
		title: message,
		text: text,
		icon: type,
		buttons: {
	    cancel: "No",
	    confirm: "Yes",
  	},
		closeOnClickOutside: false
	}).then(result => {
	  if (result) {
	    callback();
	  }
	});
}

function nerivon_alert(message, text, type, close_after, callback)
{
	if(callback == null)
	{
		callback = function(isConfirm)
		{
			return;
		};
	}

	swal({
		title: message,
		text: text,
		icon: type,
		button: "Ok",
		closeOnClickOutside: false
	}).then(result => {
	  if (result) {
	    callback();
	  }
	});
}

function warning(message, text, close_after, callback)
{
	nerivon_confirm(message, text, "warning", close_after, callback);
}

function error(message, text, close_after, callback)
{
	nerivon_alert(message, text, "error", close_after, callback);
}

function success(message, text, close_after, callback)
{
	nerivon_alert(message, text, "success", close_after, callback);
}

function info(message, text, close_after, callback)
{
	nerivon_alert(message, text, "info", close_after, callback);
}

function wait(message, text)
{
	swal({
		title: message,
		text: "Please Wait...",
		icon: "info",
		button: false,
		timer: 3000,
		closeOnClickOutside: false
	});
}

function confirm_delete(message, text, url, obj, datatable)
{
	nerivon_confirm(message, text, "warning", false, function()
	{
		wait("Deleting...", "The " + obj.toLowerCase() + " is being deleted. This dialog will close when it is complete.");

		jQuery.get
	    (
	        url,
	        function(data)
	        {
	        	success(obj + " Deleted", "The selected " + obj.toLowerCase() + " was deleted.", true, null);
    		    jQuery('#' + datatable).DataTable().ajax.reload();
    		}
	    ).fail(function(data)
	    {
    		error("Error Deleting " + obj, data, true, null);
  		});
	});
}

function table_hover(table_selector, edit_link)
{
	jQuery(table_selector + ' tbody tr td').off('click').on('click',function(e)
	{
		// Prevent going to a link if there are any anchors or buttons in this cell.
		if(jQuery(this).find("a:not(a.click), button:not(button.click), input:not(input.click)").length > 0)
		{
			return;
		}

		// Find the primary key ID from our hidden field.
		var id = jQuery(this).parent().attr("id");

		// If we have an ID, redirect to it.
		if(id != "")
		{
			edit_link = edit_link.replace("{id}", id);
			window.location = edit_link;
		}
	});
}

function setCookie(cname,cvalue,exdays)
{
	var d = new Date();
	d.setTime(d.getTime()+(exdays*24*60*60*1000));
	var expires = "expires="+d.toGMTString();
	document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
}

function getCookie(cname)
{
	var name = cname + "=";
	var ca = document.cookie.split(';');
	for(var i=0; i<ca.length; i++)
	{
		var c = ca[i].trim();
		if (c.indexOf(name)==0)
			return c.substring(name.length,c.length);
	}
	return "";
}

function getDistance(lat1, lon1, lat2, lon2)
{
	var R 		= 6371; // Radius of the earth in km
	var dLat 	= deg2rad(lat2-lat1);  // deg2rad below
	var dLon 	= deg2rad(lon2-lon1);
	var a 		= Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon/2) * Math.sin(dLon/2);
	var c 		= 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
	var d 		= R * c; // Distance in km
	return 		d;
}

function deg2rad(deg)
{
  	return deg * (Math.PI/180);
}

function updateSEOName(textbox, seoname_id)
{
    var seoname 		= document.getElementById(seoname_id);
    var seoname_text 	= getSEOName(textbox.value);

    if(seoname.value == "")
    {
        seoname.value = seoname_text;
    }
}

function getSEOName(value)
{
	//remove all non-alphanumeric (plus dash and underscore) characters
    //then replace all double-dashes with a single dash
    //then remove any leading or trailing dashes
    return value.replace(/[^A-Za-z0-9\-_]/g, "-").replace(/-{2,10}/g, "-").replace(/(^-|-$)/g, "").toLowerCase();
}
